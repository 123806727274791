

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused){
    border-color: transparent;
    overflow-y: auto;
    /* border-radius: 16px; */

}

.ck.ck-toolbar{
    border-color:transparent;
}

.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable){
    border-color: transparent;
}